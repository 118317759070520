@font-face {
  font-family: 'Comforta';
  src: url('fonts/comfortaa-bold-webfont.eot');
  src: url('fonts/comfortaa-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('fonts/comfortaa-bold-webfont.woff2') format('woff2'),
  url('fonts/comfortaa-bold-webfont.woff') format('woff'),
  url('fonts/comfortaa-bold-webfont.ttf') format('truetype'),
  url('fonts/comfortaa-bold-webfont.svg#comfortaabold') format('svg');
  font-weight: normal;
  font-style: normal;
}

.regular {
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Comforta';
  src: url('fonts/comfortaa-light-webfont.eot');
  src: url('fonts/comfortaa-light-webfont.eot?#iefix') format('embedded-opentype'),
  url('fonts/comfortaa-light-webfont.woff2') format('woff2'),
  url('fonts/comfortaa-light-webfont.woff') format('woff'),
  url('fonts/comfortaa-light-webfont.ttf') format('truetype'),
  url('fonts/comfortaa-light-webfont.svg#comfortaalight') format('svg');
  font-weight: 300;
  font-style: normal;
}

.light {
  font-weight: 300;
  font-style: normal;
}