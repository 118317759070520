@import "media";
@import "mixins";
@import "../settings";

sub {
  vertical-align: sub;
  font-size: small;
}

sup {
  vertical-align: super;
  font-size: small;
}

p {
  margin: 1em 0;
  line-height: 1.35;
}

button,
input[type="submit"] {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
}

// hide placeholders on focus
input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

/* FF 4-18 */
input:focus::-moz-placeholder {
  color: transparent;
}

/* FF 19+ */
input:focus:-ms-input-placeholder {
  color: transparent;
}

/* IE 10+ */

.field-bc {
  display: none;
}

.ul-reset {
  list-style: none;
  padding: 0;
  margin: 0;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

body {
  font-family: $font-family;
  font-size: $font-size;
  color: $textColor;
  line-height: 1;
  padding: 0;
  margin: 0;
}

table {
  border-collapse: collapse;
}

.invisible {
  opacity: 0 !important;
}

.fade {
  display: none;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.text-uppercase {
  text-transform: uppercase;
}

.smaller {
  font-size: 90%;
}

:focus {
  outline: none;
}

:focus::-webkit-input-placeholder {
  color: transparent
}

:focus::-moz-placeholder {
  color: transparent
}

:focus:-moz-placeholder {
  color: transparent
}

:focus:-ms-input-placeholder {
  color: transparent
}

/* Micro ClearFix Mixin */
.clearfix {
  zoom: 1;
  &:before, &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none;
  /* non-prefixed version, currently
                         not supported by any browser */
}

.grid {
  margin: 0 -$grid-gap/2;

  > .item {
    display: inline-block;
    vertical-align: top;
    margin: 0 $grid-gap/2 $grid-gap;
    box-sizing: border-box;
  }
}

.flex-grid {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 -$grid-gap/2;

  &__item {
    margin: 0 $grid-gap/2 $grid-gap;
    box-sizing: border-box;

    @include respond-to(mobile) {
      width: 100%;
    }
  }
}

.flex-wrapper {
  display: flex;
  justify-content: space-between;

  img {
    align-self: flex-start;
  }
}

.container {
  @extend .block-center;
  width: $site-width;
  position: relative;
}

.block-center {
  margin-left: auto;
  margin-right: auto;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.margin {
  @extend .mrg-top;
  @extend .mrg-bottom;
}

.mrg-top {
  margin-top: $margin;
}

.mrg-bottom {
  margin-bottom: $margin;
}

input[name='BC'] {
  display: none;
}

form .form-group {
  display: inline-block;
  vertical-align: top;
}

input {
  font-family: $font-family;
}

.link {
  display: inline-block;
  vertical-align: top;
  border-bottom: 1px solid;
  color: inherit;
  text-decoration: none;
  @extend .transition;

  &:hover {
    border-color: transparent;
  }
}

.linkReverse {
  display: inline-block;
  vertical-align: top;
  border-bottom: 1px solid transparent;
  color: inherit;
  text-decoration: none;
  @extend .transition;

  &:hover {
    border-color: inherit;
  }
}

.linkSpan {
  display: inline-block;
  vertical-align: top;
  color: inherit;
  text-decoration: none;

  span {
    border-bottom: 1px solid;
    @extend .transition;
  }

  &:hover {
    span {
      border-color: transparent;
    }
  }
}

.linkSpanReverse {
  display: inline-block;
  vertical-align: top;
  color: inherit;
  text-decoration: none;

  span {
    border-bottom: 1px solid transparent;
    @extend .transition;
  }

  &:hover {
    span {
      border-color: inherit;
    }
  }
}

.header {
  margin-top: 0;
  font-size: 41px;
  line-height: 1.35;
  
  &.withLine {
    position: relative;
    @extend .withLine;
  }
}

.withLine {
  position: relative;
  margin-bottom: 70px;

  &_center {
    span {
      right: 0;
      margin: 0 auto;
    }
  }

  span {
    position: absolute;
    left: 0;
    bottom: -40px;
    width: 140px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      border-top: 3px solid $lightBlueColor;
      width: 80%;
      height: 2px;
      transition: all .3s linear
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      border-top: 3px solid $lightBlueColor;
      width: 15%;
      height: 2px;
      transition: all .3s linear
    }
  }

  &:hover {
    span {

      &:before {
        animation: menu__link_beforeAdditional .5s ease-in-out;
      }

      &:after {
        animation: menu__link_afterAdditional .5s ease-in-out;
      }
    }
  }
}

@keyframes menu__link_beforeAdditional {
  0% {width: 80%}
  50% {width: 55%}
  100% {width: 80%}
}

@keyframes menu__link_afterAdditional{
  0%{width: 15%}
  50%{width: 40%}
  100%{width: 15%}
}

.button {
  display: inline-block;
  position: relative;
  border-radius: 150px;
  padding: 20px 30px;
  background-color: $textColor;
  cursor: pointer;
  transition: all 0.3s 0.1s ease-in-out;

  &__text {
    display: inline-block;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
  }

  &__s {
    display: block;
    position: absolute;
    top: 12px;
    left: 12px;
    width: 18px;
    height: 18px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    transform-origin: 100% 100%;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 35px;
      height: 35px;
      border: 2px solid #fff;
      border-radius: 100%;
    }
  }

  &:hover {
    background-color: lighten($textColor, 5%);
    .button__s {
      transform: rotate(-90deg);
    }
  }
}

.button2 {
  display: inline-block;
  position: relative;
  border: 2px solid #fff;
  border-radius: 150px;
  padding: 20px 30px;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s 0.1s ease-in-out;

  &__text {
    display: inline-block;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
  }

  &__s {
    display: block;
    position: absolute;
    top: 12px;
    left: 12px;
    width: 18px;
    height: 18px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    transform-origin: 100% 100%;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 35px;
      height: 35px;
      border: 2px solid #fff;
      border-radius: 100%;
    }
  }

  &:hover {
    background-color: #fff;
    
    .button2__text {
      color: $textColor;
    }
    
    .button2__s {
      transform: rotate(-90deg);
    }
  }
}

.button3 {
  display: inline-block;
  position: relative;
  border: 2px solid $lightBlueColor;
  border-radius: 150px;
  padding: 20px 30px;
  background-color: transparent;
  cursor: pointer;
  @extend .transition;

  &__text {
    display: inline-block;
    font-size: 14px;
    color: $lightBlueColor;
    text-transform: uppercase;
  }

  &__s {
    display: block;
    position: absolute;
    top: 12px;
    left: 12px;
    width: 18px;
    height: 18px;
    overflow: hidden;
    @extend .transition;
    transform-origin: 100% 100%;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 35px;
      height: 35px;
      border: 2px solid $lightBlueColor;
      border-radius: 100%;
      @extend .transition;
    }
  }

  &:hover {
    border-color: $textColor;
    background-color: $textColor;

    .button3__text {
      color: #fff;
    }

    .button3__s {
      transform: rotate(-90deg);
      &:before {
        border-color: #fff;
      }
    }
  }
}

.button4 {
  display: inline-block;
  position: relative;
  border: 2px solid $lightBlueColor;
  border-radius: 150px;
  padding: 20px 30px;
  background-color: $lightBlueColor;
  cursor: pointer;
  @extend .transition;

  &__text {
    display: inline-block;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
  }

  &__s {
    display: block;
    position: absolute;
    top: 12px;
    left: 12px;
    width: 18px;
    height: 18px;
    overflow: hidden;
    @extend .transition;
    transform-origin: 100% 100%;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 35px;
      height: 35px;
      border: 2px solid #fff;
      border-radius: 100%;
      @extend .transition;
    }
  }

  &:hover {
    border-color: $textColor;
    background-color: $textColor;

    .button4__text {
      color: #fff;
    }

    .button4__s {
      transform: rotate(-90deg);
    }
  }
}

.text {
  font-size: 18px;
  color: $textLightColor;
  line-height: 1.35;
}

.paddingLeft {
  padding-left: $paddingLeft;
}

