body {
  min-width: 1280px;
  &.hidden {
    overflow: hidden;
    padding-right: 17px;
  }
}
.mainHeader {
  padding: 10px 0 0 0;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  &__popupOpen {
    position: fixed;
    right: -100px;
    top: 20px;
    z-index: 55;
    border: 3px solid #2f2c2c;
    border-radius: 100%;
    width: 70px;
    height: 70px;
    box-sizing: border-box;
    transition: all .3s ease;
    transform: scale(1);

    &:hover {
      span {
        width: 70% !important;
      }
    }

    span {
      display: block;
      margin: 3px auto;
      border-radius: 10px;
      height: 3px;
      background: #fff;
      transition: all .3s ease;

      &:first-child {
        margin-top: 19px;
        width: 25%;
      }

      &:nth-child(2) {
        width: 50%
      }

      &:last-child {
        width: 40%
      }
    }
  }

  &__popupOpenInner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid #fff;
    border-radius: 100%;
    width: 62px;
    height: 62px;
    background-color: #f15b26;
    box-sizing: border-box;
  }
}

.logo {
  display: inline-block;
  flex: 0 0 260px;
  width: 260px;
  height: 70px;
  padding: 23px 0 0 0;
  text-decoration: none;
  color: $textColor;
  
  img {
    float: left;
    margin-right: 15px;
  }

  &__top {
    display: block;
    margin-top: 8px;
    font-size: 27px;
    text-transform: uppercase;
    color: #2f2c2c;
  }

  &__bottom {
    display: block;
    margin-top: 2px;
    font-size: 10px;
    text-transform: uppercase;
  }
}

.menu {
  @extend .ul-reset;
  display: flex;
  justify-content: space-between;

  li {
    position: relative;
    margin: 0 15px;

    &:hover {
      .menu__sub {
        visibility: visible;
        opacity: 1;
        transform: translate(0, 0);
      }

      .menu__link {
        color: $lightBlueColor;

        &:before {
          opacity: 1;
          animation: menu__link_before .5s ease-in-out;
        }

        &:after {
          opacity: 1;
          animation: menu__link_after .5s ease-in-out;
        }
      }
    }
  }

  span.menu__link {
    cursor: default;
  }

  &__link {
    display: block;
    position: relative;
    padding: 15px 0;
    font-size: 16px;
    color: #484848;
    text-decoration: none;
    transition: all .3s linear;

    &:before {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      border-top: 2px solid $lightBlueColor;
      bottom: 5px;
      width: 80%;
      height: 2px;
      opacity: 0;
      transition: all .3s linear
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 5px;
      border-top: 2px solid $lightBlueColor;
      width: 15%;
      height: 2px;
      opacity: 0;
      transition: all .3s linear
    }
  }

  &__sub {
    position: absolute;
    z-index: 9999;
    left: -25px;
    margin-top: 0;
    width: 210px;
    top: 45px;
    background-color: #fff;
    padding: 20px 15px 30px !important;
    border-radius: 5px;
    box-shadow: 15px 15px 30px rgba(0,0,0,0.16);
    list-style: none;
    visibility: hidden;
    opacity: 0;
    transform: translate(0, 100px);
    transition: opacity 0.4s 0.1s ease-in-out, transform 0.4s ease-in-out;

    li {
      margin: 7px 0;
    }

    &+.menu__link {
      margin-right: 25px;

      span {
        position: relative;

        &:before {
          content: '';
          display: block;
          position: absolute;
          right: -15px;
          top: 3px;
          width: 10px;
          height: 9px;
          background-image: url(../img/arrowmenu.png);
        }
      }


    }
  }

  &__subHeader {
    font-size: 24px;
    line-height: 1.2;
  }

  &__subDescr {
    font-size: 14px;
    color: #acacac;
    line-height: 1.35;
  }

  &__subItem {

  }

  &__subLink {
    display: block;
    position: relative;
    padding: 5px 0;
    font-size: 15px;
    color: #757575;
    text-decoration: none;
    transition: all .4s ease-out;

    &:hover {
      color: $lightBlueColor;
    }
  }
}

@keyframes menu__link_before {
  0% {width: 100%}
  50% {width: 55%}
  100% {width: 80%}
}
@keyframes menu__link_after{
  0%{width: 0}
  50%{width: 40%}
  100%{width: 15%}
}

.tools {

  &__inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }

  &__item {
    margin-bottom: 30px;
    border-radius: 10px;
    flex: 0 0 290px;
    width: 290px;
    height: 249px;
    padding: 336px 30px 0 40px;
    background-size: cover;

    &_tool {
      border-radius: 0;
    }

    &_first, &_last {
      flex: 0 0 360px;
      width: 360px;
      height: auto;
      padding: 0;
    }

    &_last {
      position: relative;
      left: 50px;
      flex: 0 0 780px;
      width: 780px;
      height: 589px;
      background: url(../img/rock.png) no-repeat right bottom;
    }
  }

  &__descr {
    margin-bottom: 30px;
    color: $textLightColor;
    line-height: 1.35;
  }

  &__itemHeader {
    display: inline-block;
    margin-bottom: 20px;
    padding: 0 5px;
    font-size: 23px;
    color: $textColor;
    line-height: 1.2;
    text-decoration: none;
  }

  &__itemInfo {
    margin-bottom: 25px;
    padding: 0 5px;
    font-weight: 200;
    font-size: 18px;
    color: #fff;
    line-height: 1.35;
  }

  &__itemButton {
    display: block;
    margin-left: 5px;
    width: 27px;
    height: 19px;
    background-image: url(../img/arrowRight.png);
    text-decoration: none;
  }
}

.clients {
  margin-top: 60px;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__left {
    flex: 0 0 490px;
    padding-left: $paddingLeft;
  }

  &__right {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex: 0 0 490px;
  }

  &__image {
    margin-bottom: 20px;
  }

  &__text {
      margin-bottom: 30px;
      color: $textLightColor;
      line-height: 1.35;
  }
}

.mountains {
  height: 883px;
  padding-top: 245px;
  background: url(../img/mountains.png) no-repeat center center;
  box-sizing: border-box;

  &__inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 70px;
    flex: 0 0 555px;
  }

  &__image {
    margin: 0 25px 0 0;
    border: 2px solid #e5e7e7;
    border-radius: 100%;
    flex: 0 0 200px;
    width: 200px;
    height: 200px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 166px 166px;
  }

  &__info {

  }

  &__header {
    margin-bottom: 20px;
    font-size: 24px;
    text-transform: uppercase;
  }

  &__text {
    @extend .text;
  }
}

.innovate {
  margin: 0 0 100px;

  &__bigImage {
    margin-bottom: 30px;
    height: 470px;
    background: url(../img/innovate.png) no-repeat center center;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    position: relative;
    left: 22px;
  }

  &__item {
    flex: 0 0 275px;
    padding: 0 0 0 100px;
    background-repeat: no-repeat;
    background-position: -8px -10px;
  }

  &__header {
    margin-bottom: 11px;
    font-size: 18px;
    line-height: 1.35;
  }

  &__text {
    @extend .light;
    font-size: 16px;
    line-height: 1.35;
  }
}

.brifs {
  @extend .margin;
  margin-bottom: 50px;

  .tools__item {
    border-radius: 10px;
  }
}

.catalog {
  margin-bottom: 50px;

  &__categories {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
    padding: 0 100px;
  }

  &__categoriesItem {
    margin: 0 8px 15px;
    border-radius: 100px;
    padding: 14px 15px;
    background-color: $lightGreyColor;
    font-size: 19px;
    color: #acacac;
    cursor: pointer;
    @extend .transition;
    
    &:hover, &.active {
      background-color: $lightBlueColor;
      color: #fff;
    }
  }

  &__inner {
    margin-top: 25px;
    @extend .transition;
  }
  
  &__item {
    margin-bottom: 30px;
    width: 355px;
    padding: 60px 0 55px;
    background-color: $lightGreyColor;
    text-align: center;
    text-decoration: none;
    color: $textColor;

    &:hover {
      background-color: $lightBlueColor;
      @extend .transition;

      .catalog__itemImage {
        filter: brightness(50);
      }

      .catalog__itemText {
        color: #fff;
      }

      .button3 {
        border-color: $textColor;
        background-color: $textColor;
      }

      .button3__text {
        color: #fff;
      }

      .button3__s {
        &:before {
          border-color: #fff;
        }
      }
    }
  }

  &__itemImage {
    width: 210px;
    max-height: 170px;
  }

  &__itemHeader {
    display: block;
    margin: 60px 0 30px;
    font-size: 19px;
    text-transform: uppercase;
    line-height: 1.35;
  }

  &__itemText {
    @extend .text;
    display: block;
    margin-bottom: 30px;
    padding: 0 45px;
    color: #9e9e9e;
    @extend .transition;
  }
}

.loadMore {
  margin: 20px auto 0;
  width: 170px;
  text-align: center;
  cursor: pointer;

  span {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 70px;
    background: url(../img/load-more-arrow.png) no-repeat 62% center;
  }

  &__text {
    margin-top: 20px;
    color: #d7d7d7;;
    font-size: 16px;
    text-transform: uppercase;
    @extend .transition;
  }

  &.active {
    span {
      animation: rotateLoadMore 1.2s infinite linear;
    }

    .loadMore__text {
      color: $lightBlueColor;
    }
  }

  &:hover {

    .loadMore__text {
      color: $lightBlueColor;
    }
  }
}

@keyframes rotateLoadMore {
  to { transform: rotate(360deg); }
}

.how {

  &__line {
    position: relative;
    margin: 60px 0 120px;
    height: 6px;
    background-color: $orangeColor;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 12;
      margin: auto 0;
      width: 10px;
      background: linear-gradient(to right, #ffffff 0%, rgba(255, 255, 255, 0.3) 100%);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 12;
      margin: auto 0;
      width: 10px;
      background: linear-gradient(to right, rgba(255, 255, 255, 0.3) 0%, #fff 100%);
    }
  }

  &__lineForward {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    margin: auto 0;
    width: 0;
    background: $lightBlueColor;
    transition: all 0.5s linear;
  }

  &__circle {
    position: absolute;
    top: -13px;
    z-index: 11;
    width: 80px;
    text-align: center;
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      margin: 0 auto 10px;
      border-radius: 100%;
      border: 2px solid $lightBlueColor;
      width: 28px;
      height: 28px;
      background-color: #fff;
      @extend .transition;
    }

    &_active {
      &:before {
        background-color: $lightBlueColor;
      }
    }

    &_after {
      &:before {
        border-color: $orangeColor;
      }
    }
  }

  &__month {
    font-size: 0.8em;
  }

  &__sliderItem {
    display: flex;
    justify-content: space-between;
  }

  &__sliderImage {
    margin-right: 30px;
    flex: 0 0 275px;
    height: 295px;
    background-position: center top;
  }

  &__sliderInfo {
    padding: 40px 0;
  }

  &__sliderHeader {
    margin: 25px 0 20px;
    font-size: 35px;
  }
}

.waves {
  position: relative;
  margin-top: 200px;
  padding-bottom: 400px;
  background-color: $lightLightGreyColor;
  box-sizing: border-box;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: -100px;
    z-index: 5;
    margin: 0 auto;
    height: 200px;
    background: url(../img/wavesSmall.png) no-repeat center top;
  }

  .container {
    z-index: 10;
  }

  &+.mainFooter {
    margin-top: 0;
  }

  &.beforeFooter {

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 5;
      margin: 0 auto;
      height: 560px;
      background: url(../img/waves.png) no-repeat center bottom;
    }
  }
}

.materials {

  &__inner {

  }

  &__item {
    margin-bottom: 30px;
    box-shadow: 3px 10px 23px rgba(146, 174, 204, 0.49);
    width: 360px;
  }

  &__itemImage {
    display: block;
    height: 200px;
    background-size: cover;
  }

  &__itemContent {
    box-shadow: 0 2px 5px 0 #bbb;
    padding: 24px 30px;
    background-color: #fff;
  }

  &__itemCategory {
    margin: 10px 0;
    font-size: 14px;
    color: #4caf50;
  }

  &__itemDate {
    margin: 0px 0px 10px;
    font-size: 14px;
    color: #777777;
    font-weight: 200;
  }

  &__itemName {
    @extend .linkSpanReverse;
    margin: 0 0 10px;
    font-weight: bold;
    font-size: 19px;
    color: #3C4858;
    text-decoration: none;
    line-height: 26px;
  }

  &__itemText {
    @extend .text;
    font-size: 15px;
    color: #999999;
  }
}

.mainFooter {
  position: relative;
  margin-top: 400px;
  padding: 100px 0 0 0;
  background-color: #151414;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: -560px;
    z-index: -1;
    margin: 0 auto;
    height: 560px;
    background: url(../img/ffooter.png) no-repeat center center;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  &__left {
    flex: 0 0 670px;
  }

  &__right {
    flex: 0 0 360px;
  }

  &__header {
    font-size: 36px;
    color: #fff;
  }

  &__menu {
    @extend .ul-reset;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
      position: relative;
      margin-bottom: 18px;
      flex: 0 0 165px;
      padding-left: 15px;
      transition: all .3s ease;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 5px;
        width: 0;
        height: 0;
        border-left: 4px solid #acacac;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        transition: all .3s ease;
      }

      &:hover {
        transform: translate(10px, 0);

        &:before {
          border-left: 4px solid $lightBlueColor;
        }

        a {
          color: $lightBlueColor;
        }
      }
    }

    a {
      display: block;
      font-size: 14px;
      color: #acacac;
      text-decoration: none;
      transition: all .3s ease;
    }
  }

  &__text {
    @extend .text;
    color: #fff;
    opacity: 0.4;
  }

  &__icons {
    display: flex;
    justify-content: space-between;
    padding: 35px 0;
    border-top: 2px solid #2d2c2c;
  }

  &__icon {
    flex: 0 0 33%;
    text-decoration: none;
    color: inherit;

    &:hover {
      .mainFooter__iconTop {
        color: $lightBlueColor;
      }
    }
  }

  &__iconImage {
    margin-right: 25px;
    float: left;

    svg {
      width: 70px;
      height: 70px;
    }
  }

  &__iconTop {
    display: block;
    margin: 10px 0;
    font-size: 24px;
    color: #fff;
    @extend .transition;
  }

  &__iconBottom {
    display: block;
    font-size: 16px;
    color: #fff;
    opacity: 0.4;
  }

  &__bottom {
    padding: 60px 0;
    background-color: #121111;
    font-size: 14px;
    color: #504D4E;

    a {
      text-decoration: none;
      color: rgba(255, 255, 255, 0.35);
      transition: all .3s ease;

      &:hover {
        color: $lightBlueColor;
      }
    }
  }
}

.socials {
  margin-top: 25px;

  &__item {
    margin-right: 15px;
    text-decoration: none;

    img {
      height: 24px;
    }
  }
}

.contentWithImage {
  position: relative;
  margin-top: 100px;
  padding-bottom: 40px;
  background-color: $lightLightGreyColor;

  &.toolBlock {
    margin-bottom: 160px;

    &:after {
      bottom: -90px;
    }
  }

  &.case {
    padding-top: 0;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: -100px;
    z-index: 5;
    margin: 0 auto;
    height: 200px;
    background: url(../img/wavesSmall.png) no-repeat center top;
  }

  .container {
    z-index: 10;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  &__left {
    flex: 0 0 500px;

    img {
      width: 500px;
      height: 450px;
    }
  }

  &__right {
    margin-left: 50px;
    flex: 1 1 auto;

    p {
      @extend .text;
    }
  }
}

.advantages {
  position: relative;
  padding: 120px 0;
  background-color: #00bff3;

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    position: relative;
    flex: 0 0 23%;
    padding-bottom: 20px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 10px;
      width: 20px;
      height: 3px;
      background-color: #64d8ff;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 30px;
      border-radius: 10px;
      width: 50px;
      height: 3px;
      background-color: #64d8ff;
    }
  }

  &__icon {
    height: 70px;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: auto 50px;
  }

  &__digit {
    font-size: 100px;
    color: rgba(255, 255, 255, 0.43);
    line-height: 1;
  }

  &__text {
    color: #fff;
    font-size: 24px;
    line-height: 1.35;
  }
}

.case {
  position: relative;
  padding: 30px 0 500px;
  background-color: $lightLightGreyColor;

  .container {
    z-index: 10;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    margin: 0 auto;
    height: 560px;
    background: url(../img/waves.png) no-repeat center bottom;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  &__left {
    flex: 0 0 600px;
  }

  &__right {
    flex: 0 0 480px;
  }

  &__image {
    display: block;
    margin-bottom: 40px;
    font-size: 0;

    img {
      width: 600px;
      height: 600px;
    }
  }

  &__part {
    margin-top: 40px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__icon {
    padding: 20px 0 20px 90px;
    background-repeat: no-repeat;
    background-position: left center;
  }

  &__iconHeader {
    font-size: 29px;
  }

  &__iconText {
    font-size: 19px;
    color: $textLightColor;
  }

  &__partContent {
    @extend .text;
  }

  &+.mainFooter {
    margin-top: 0;
  }
}

.content {

  p {
    @extend .text;
  }

  li {
    margin: 20px 0;
    @extend .text;
  }

  ul {
    margin-left: 20px;
    @extend .ul-reset;

    li {
      position: relative;
      padding-left: 30px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        left: 0;
        width: 15px;
        height: 15px;
        background-image: url(../img/galka.png);
        background-size: 100% 100%;
      }
    }
  }
}

.toolBlock {
  position: relative;
  margin-bottom: 200px;
  background-color: $lightLightGreyColor;
  box-sizing: border-box;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -130px;
    z-index: -1;
    margin: 0 auto;
    height: 200px;
    background: url(../img/wavesSmall.png) no-repeat center top;
    transform: rotate(180deg);
  }

  .container {
    padding-top: 1px;
  }

  &__item {
    display: flex;
    justify-content: space-around;
    margin: 65px 0;
  }

  &__left {
    flex: 0 0 50%;
  }

  &__right {
    flex: 0 0 48%;
  }

  &_imageRight {
    .toolBlock__left {
      flex: 0 0 50%;
    }

    .toolBlock__right {
      flex: 0 0 48%;
    }
  }

  &_imageLeft {
    .toolBlock__left {
      flex: 0 0 48%;
    }

    .toolBlock__right {
      flex: 0 0 50%;
    }
  }

  img {
    max-width: 100%;
  }

  .case__icons {
    display: flex;
    justify-content: space-around;
  }
}

.openPopup {
  cursor: pointer;
}

.popupForm {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 55;
  box-shadow: 0 0 10px 0 #bbb;
  width: 420px;
  height: 100%;
  background-color: #f7f9f9;
  box-sizing: border-box;
  transform: translate(600px, 0);
  transition: all .4s ease;

  &__overlay {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &__outer {
    position: relative;
    z-index: 52;
    height: 100%;
    overflow: auto;
  }

  &__inner {
    padding: 30px 0 0 75px;
    background-color: #fff;
  }

  &.active {
    transform: translate(0, 0);

    .popupForm__overlay {
      display: block;
    }
  }

  .close {
    z-index: 53;
    left: -35px;
    top: 80px;
  }

  &__header {
    font-size: 30px;
    margin: 40px 0 20px;
  }

  .input {
    border-radius: 50px;
    background-color: #f7f9f9;
    margin-bottom: 20px;
    padding: 20px 40px;
    border-color: transparent;
    font-size: 16px;
    color: #acacac;
    transition: all .3s ease;

    &:focus {
      background-color: #fff;
      box-shadow: 17px 0 60px #d3dcdc;
      color: $lightBlueColor;
    }
  }
}

.close {
  position: absolute;
  right: -100px;
  bottom: 5px;
  border: 3px solid #2f2c2c;
  border-radius: 100%;
  width: 70px;
  height: 70px;
  box-sizing: border-box;
  transition: all .3s ease;
  cursor: pointer;

  span {
    display: block;
    height: 3px;
    background: #fff;
    border-radius: 10px;
    margin: auto;
    width: 50%;
    transition: all .3s ease;
    left: 25%;
    top: 50%;
    position: absolute;

    &:first-child {
      transform: rotate(-45deg);
    }

    &:last-child {
      transform: rotate(45deg);
    }
  }

  &__inner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid #fff;
    border-radius: 100%;
    width: 62px;
    height: 62px;
    background-color: #f15b26;
    box-sizing: border-box;
  }
}

.success {
  display: none;

  &__wrapper {
    width: 590px !important;
    padding: 30px !important;
    background-color: #fff;
    text-align: center;

    .logo {
      padding: 0;
    }
  }

  &__text {
    @extend .text;
  }
}

.error {
  border: 2px solid red !important;
}

.brifForm {
  margin: 50px auto;
  width: 700px;

  &__item {
    position: relative;
    margin: 40px 0;
  }

  &__text {
    margin-bottom: 5px;
    @extend .text;
  }

  .input {
    border: 2px solid $lightBlueColor;
    border-radius: 20px;
    width: 100%;
    font-family: $font-family;
    font-size: 18px;
    padding: 10px 15px;
    box-sizing: border-box;
    @extend .transition;

    &:focus {
      border-color: #878787;

      &+.brifForm__digit {
        background-color: #878787;
      }
    }

    &.error {

      &+.brifForm__digit {
        background-color: red;
      }
    }
  }

  textarea {
    height: 220px;
  }

  &__digit {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: -100px;
    top: 10px;
    border-radius: 100%;
    width: 72px;
    height: 72px;
    background-color: $lightBlueColor;
    @extend .transition;
  }

  &__digitDigit {
    font-size: 36px;
    color: #fff;
  }

  &__digitLine {

  }
}

.contacts {
  margin: 30px auto;
  width: 1100px;

  &__icons {
    display: flex;
    justify-content: space-between;
  }

  &__icon {
    flex: 0 0 30%;
    text-decoration: none;
    color: $textColor;
  }

  &__iconImage {
    float: left;
    margin-right: 20px;
  }

  &__iconTop {
    display: block;
    margin-bottom: 10px;
    padding-top: 10px;
    font-size: 24px;
  }

  &__iconBottom {
    display: block;
    font-size: 17px;
    color: $textLightColor;
  }
}

.contactsColumns {
  display: flex;
  justify-content: space-between;

  &__left {
    flex: 0 0 340px;
  }

  &__right {
    flex: 0 0 700px;
  }
}

.w-contacts .contacts-icon{max-width:48px;max-height:48px}.w-contacts{display:block}.w-contacts .contacts-title, .w-contacts .heading-title{font-size:30px;margin-bottom:20px}.w-contacts .contacts-text{margin-bottom:30px}.w-contacts .contacts-item{margin-bottom:20px}.w-contacts .contacts-item img{float:left;margin:auto 30px auto auto}.w-contacts .contacts-item
                                                                                                                                                                                                                                                                                                                    .content{overflow:hidden}.w-contacts .contacts-item .content
                                                                                                                                                                                                                                                                                                                                             a{font-size:22px}.w-contacts .contacts-item .sub-title{font-size:14px;color:#757575;margin-bottom:0}
.w-contacts {
  margin: 40px 0 0 -75px;
  padding: 30px 0 40px 75px;
  background-color: #f7f9f9;

  .contacts__icons {
    flex-wrap: wrap;
  }

  .contacts__icon {
    flex: 0 0 100%;
  }

  .contacts__iconTop {
    font-size: 20px;
  }

  .contacts__iconBottom {
    font-size: 14px;
    color: #757575;
  }
}

.block2 {
  margin: 65px 0;
  height: 508px;
  background: url(../img/hghgh.jpg) no-repeat center center;

  &__inner {
    width: 30%;
    padding: 70px 0 90px;
  }

  &__header {
    margin-bottom: 15px;
    font-size: 30px;
  }

  &__underHeader {
    font-size: 10px;
    text-transform: uppercase;
  }

  &__text {
    margin-top: 50px;
    margin-bottom: 30px;
    @extend .text;
  }
}