$site-width: 1140px;

$font-family: Comforta, Arial, sans-serif;
$font-size: 18px;

$grid-gap: 10px;

$margin: 30px;
$paddingLeft: 35px;

$textColor: #2f2c2c;
$textLightColor: #757575;
$lightBlueColor: #4cc2c0;
$lightGreyColor: #f7f9f9;
$lightLightGreyColor: #fafafa;
$orangeColor: #fecb16;